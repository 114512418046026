import React, { useContext } from "react";

import BlurredContentHoc from "@/components/common/blurredContent";
import PromoBlock from "@/components/common/promoBlock";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import useTextContents from "@/hooks/useTextContents";
import { isWideBannerActive } from "@/utils/bannerUtils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import BulletLinkBlock from "../bulletLinkBlock";
import Constructor from "../constructor";
import LabelLinkBlock from "../labelLinkBlock";
import LinkBlock from "../linkBlock";
import { LinkUrlPrefixEnum } from "../types";
import { getLinkBlockObject, shouldLinkBeBullet } from "../utils";

import { birthdateItems, emailItems } from "./constants";
import { GoroskopProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const Goroskop = ({ pageContext }: GoroskopProps) => {
  const {
    seoId,
    title = "Статья на Lunaro",
    ogTitle,
    description = "Статья на Lunaro",
    textTop,
    textMiddle,
    textBottom,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    goroskopLinks,
    autolinkedGoroskopLinks,
    url,
    expertsInitial,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
    wideBannerData,
    isContentsShown,
  } = pageContext;
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);

  const {
    HeadingsLayout,
    convertedTextBottom,
    convertedTextMiddle,
    convertedTextTop } = useTextContents({ textTop, textMiddle, textBottom, isContentsShown });

  const fieldItems = [...emailItems, ...birthdateItems];

  const wideBannerBannerIsShown = isWideBannerActive(
    wideBannerData.wideBannerIsShownOnGoroskop,
    isUserLoggedIn,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
  );

  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={`${textTop}${textMiddle}${textBottom}`?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      {wideBannerBannerIsShown && <PromoBlock wideBannerData={wideBannerData} />}
      <div
        className="bg--white goroskop"
      >
        <div
          itemScope
          itemType="https://schema.org/Article"
          className="page-width"
        >
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Goroskop}
          />
          <h1 itemProp="headline" className="goroskop__headline">
            {header || ""}
          </h1>
          <HeadingsLayout />
          {/* <BlurredContentHoc
            fieldItems={fieldItems}
            textTop={textTop}
            textMiddle={textMiddle}
            textBottom={textBottom}
            textClassName="seo-typography"
          > */}
          <>
            {!!textTop && (
            <div
              itemProp="articleBody"
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(convertedTextTop || ""),
              }}
            />
            )}
            <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
            {!!textMiddle && (
            <div
              itemProp="articleBody"
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(convertedTextMiddle || ""),
              }}
            />
            )}
            <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
            {!!goroskopLinks?.length && (
            <LinkBlock links={getLinkBlockObject(goroskopLinks, LinkUrlPrefixEnum.Goroskop)} />
            )}
            {!!textBottom && (
            <div
              itemProp="articleBody"
              className="seo-typography"
              dangerouslySetInnerHTML={{
                __html: cleanupFromDangerousTags(convertedTextBottom || ""),
              }}
            />
            )}
          </>

          {/* </BlurredContentHoc> */}
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
          {!!autolinkedGoroskopLinks?.length && (
            <>
              <h2 className="goroskop__more">Смотрите ещё</h2>
              {shouldLinkBeBullet(autolinkedGoroskopLinks?.length ?? 0) ? (
                <BulletLinkBlock
                  links={autolinkedGoroskopLinks}
                  urlPrefix={LinkUrlPrefixEnum.Goroskop}
                />
              ) : (
                <LabelLinkBlock
                  links={autolinkedGoroskopLinks}
                  urlPrefix={LinkUrlPrefixEnum.Goroskop}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Goroskop;
